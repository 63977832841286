import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Header, Progress } from "semantic-ui-react";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { filter, find, first, isEmpty, noop, size } from "lodash";
import "./FilesList.scss";
import CONFIG from "configs/config";
import CustomActionButton from "components/CustomActionButton/CustomActionButton";
import { AALinkName, onAAClickEvents } from "analytics/AnalyticsCapture";
import { formatDate, formatDateAndTime } from "utils/helpers";
import FileUploadStatus from "components/FileUploadStatus/FileUploadStatus";
import { fileListType } from "utils/customPropTypes";

import {
  getFileProgress,
  canShowProgressBar,
  canSelectFile
} from "utils/common";

import UploadedPptIcon from "assets/images/uploaded-ppt-icon.svg";

/**
 * HOME SCREEN
 * Show file list with status of files
 * @param {*} param
 * @returns JSX
 */
const FilesList = ({
  filesList,
  setShowDeleteConfirmationModal,
  selectedFile,
  setSelectedFile,
  setShowAutoSanitizationModal,
  getPromptAction,
  text
}) => {
  const titleWithCount = `${CONFIG.LABELS.ALL_DOCUMENTS} (${filesList.length})`;

  const onDeleteCall = (pptId, pptTitle, index) => {
    onAAClickEvents(pptTitle, "Delete_file");
    setShowDeleteConfirmationModal({
      pptId: pptId,
      index
    });
  };

  useEffect(() => {
    if (
      isEmpty(selectedFile) ||
      !find(filesList, { pptId: selectedFile.pptId })
    ) {
      const fileToSelect = first(
        filter(filesList, (file) => canSelectFile(file))
      );
      if (!isEmpty(fileToSelect)) {
        setSelectedFile(fileToSelect);
      } else if (!isEmpty(selectedFile)) {
        setSelectedFile({});
      }
    }
  }, [filesList]);

  const totalProgressSteps = size(CONFIG.CONSTANTS.DISPLAY_FILE_STATUS);

  return (
    <>
      <Header as="h4" className="mt-0 pl-2">
        {titleWithCount}
      </Header>
      <SimpleBar className="file-list__card-container">
        {filesList.map((file, index) => {
          const canSelect = canSelectFile(file);
          const canShowProgress = canShowProgressBar(file);
          const completedSteps = getFileProgress(file);
          return (
            <React.Fragment key={file.pptId}>
              <div
                className={`file-list__card-content pointer card-fadein-animation ${
                  selectedFile.pptId === file.pptId
                    ? "file-list__highlighted-card"
                    : ""
                } ${!canSelect ? "cursor-default" : ""}
                 ${canShowProgress ? "showing-progree-bar" : ""}`}
                onClick={() => {
                  if (canSelect) {
                    setSelectedFile(file);
                  }
                }}
                data-testid="file-card"
              >
                <div className="file-list__card-content-elements">
                  <div className="file-list__ppt-icon">
                    <img alt="uploaded-ppt-icon" src={UploadedPptIcon} />
                  </div>
                  <div className="file-list__name-container">
                    <div className="word-break">{file.pptTitle}</div>
                    <small className="text-gray">
                      {file.totalSlide
                        ? `Modified - ${formatDate(file.modifiedOn)}`
                        : `New Upload - ${formatDate(file.createdOn)}`}
                    </small>
                  </div>
                  <FileUploadStatus
                    status={file.pptStatus}
                    totalSlide={file.totalSlide}
                    completedSlides={file.completedSlides}
                  />
                  <span
                    data-testid="trash-icon"
                    className={`${
                      CONFIG.CONSTANTS.ALLOWED_STATUS_TO_DELETE.includes(
                        file.pptStatus
                      )
                        ? "file-list__trash-icon"
                        : "file-list__trash-placeholder"
                    } ${
                      file.pptId === selectedFile.pptId
                        ? "file-list__trash-icon--selected"
                        : ""
                    }`}
                    onClick={() =>
                      onDeleteCall(file.pptId, file.pptTitle, index)
                    }
                    role="presentation"
                  />
                </div>
                {file.downloadDate ? (
                  <div className="file-list__foot-note">
                    <small className="text-warning">
                      Last Download Date: {formatDate(file.downloadDate)}. The
                      file will be deleted on{" "}
                      {formatDateAndTime(file.deleteDate)}
                    </small>
                  </div>
                ) : null}
              </div>
              {canShowProgress && (
                <Progress
                  percent={(completedSteps / totalProgressSteps) * 100}
                  size="small"
                  attached="bottom"
                  success
                />
              )}
            </React.Fragment>
          );
        })}
      </SimpleBar>
      {CONFIG.CONSTANTS.SUCCESS_STATUSES.includes(selectedFile.pptStatus) ? (
        <div className="file-list__actions my-3 w-100">
          <CustomActionButton
            className="ui button primary d-none"
            label={CONFIG.LABELS.AUTO_SANITIZE}
            onClickfn={() => {
              onAAClickEvents(selectedFile.pptTitle, "Auto_Sanitize");
              setShowAutoSanitizationModal(true);
            }}
            noApplyIcon
          />
          <Link
            onClick={() => {
              getPromptAction({
                gptPrompt: text
              });
              (selectedFile.totalSlide &&
                selectedFile.totalSlide === selectedFile.completedSlides) ||
              selectedFile.totalSlide
                ? AALinkName("Continue_sanitization", "Link_click")
                : onAAClickEvents(selectedFile.pptTitle, "Start_sanitization");
            }}
            to={`/sanitize/${selectedFile.pptId}`}
            className="ui button primary mr-0"
          >
            {(selectedFile.totalSlide &&
              selectedFile.totalSlide === selectedFile.completedSlides) ||
            selectedFile.totalSlide
              ? CONFIG.LABELS.CONTINUE_SANITIZATION
              : CONFIG.LABELS.START_SANITIZATION}
          </Link>
        </div>
      ) : null}
    </>
  );
};

FilesList.propTypes = {
  filesList: PropTypes.arrayOf(fileListType),
  setShowDeleteConfirmationModal: PropTypes.func,
  selectedFile: PropTypes.shape({
    pptId: PropTypes.string,
    pptTitle: PropTypes.string,
    pptStatus: PropTypes.string,
    totalSlide: PropTypes.number,
    completedSlides: PropTypes.number
  }),
  setSelectedFile: PropTypes.func,
  setShowAutoSanitizationModal: PropTypes.func,
  getPromptAction: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

FilesList.defaultProps = {
  filesList: [],
  setShowDeleteConfirmationModal: noop,
  selectedFile: {},
  setSelectedFile: noop,
  setShowAutoSanitizationModal: noop
};

export default FilesList;
