import { createAction as ca } from "utils/action";
import * as at from "../types";

export const setDisclaimerStatusAction = ca(at.SET_DICLAIMER_STATUS);

export const uploadPowerPointAction = ca(at.UPLOAD_POWER_POINT_FILES);

export const updateFileStatusByIdAction = ca(at.UPDATE_FILE_STATUS_BY_ID);

export const getFilesAction = ca(at.GET_FILES);

export const deleteFileAction = ca(at.DELETE_FILE);

export const getPromptAction = ca(at.GET_PROMPT);

export const resetHomeStore = ca(at.RESET_HOME_STORE);

export const onHomePageRedirect = ca(at.ON_HOME_PAGE_REDIRECT);

export const autoSanitizeDocument = ca(at.AUTO_SANITIZE);

export const setSelectedPPTId = ca(at.SET_SELECTED_PPT_ID);

export const checkForRetryFailedDocument = ca(
  at.CHECK_FOR_RETRY_FAILED_DOCUMENT
);
