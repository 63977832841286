// General
export const PROCEED = "Proceed";
export const CLOSE = "Close";
export const CONFIRM = "Confirm";
export const DISMISS = "Dismiss";
export const DELETE = "Delete";
export const CLICK_HERE = "click here";
export const APPLY = "Apply";
export const APPLY_ALL = "Apply All";
export const RESET_MANUAL_CHANGE = "Reset Manual Changes";
export const MANUAL_EDIT = "Manual Edit";
export const RESET_TO_ORIGINAL = "Reset to Original";
export const BACK = "Back";
export const NEXT = "Next";
export const EXIT_TABLE_MANUAL_EDIT = "Exit Manual Edit";

// Button and Actions
export const DOWNLOAD_DOC = "Download Document";
export const DOWNLOAD = "Download";
export const PREVIEW_DOC = "Preview Document";
export const PREVIEW_SLIDE = "Preview";

// Image smart options
export const SMART_EDIT = "Smart Edit";
export const PATCH = "Patch";
export const BLUR = "Blur";
export const STAMP = "Stamp";

// Image editor
export const UNDO = "Undo";
export const REDO = "Redo";
export const RESET = "Reset";
export const CANCEL = "Cancel";
export const SAVE = "Save";

// ---------------- UPLOAD FILE ------------ //
export const AGREE_DISCLAIMER = "I Agree";
export const AUTO_SANITIZE = "Auto Sanitize";
export const START_SANITIZATION = "Start Sanitization";
export const CONTINUE_SANITIZATION = "Continue Sanitization";
export const ALL_DOCUMENTS = "All Documents";

export const UPLOAD_DOCUMENTS = "Upload Documents";
export const PROMPT = "Prompt";
export const DRAG_AND_DROP_OR_BROWSE = "DRAG & DROP OR BROWSE";
export const DROP_FILES_HERE = "DROP FILES HERE";
export const MULTIPLE_FILES_ARE_ALLOWED = "Multiple files are allowed";
export const CURRENLTY_ONLY_PPT_FILES_ALLOWED =
  "Currently only powerpoint documents are supported";
export const MAX_TEN_CONSIDERED_RESET_DISCARDED_WARNING =
  "A maximum of 10 files will be considered for processing, rest will be discarded";
export const MAX_PPT_FILES_SUPPORTED_WARNING =
  "Maximum 10 PPT files are supported. Other files will be discarded for processing";
export const ONLY_PPT_FILES_SUPPORTED_WARNING =
  "Only .PPTX files are supported. Other file types will be discarded for processing";
export const FILE_TYPE_NOT_SUPPORTED_WARNING =
  "This file type is not supported";
export const YOU_HAVENT_UPLOADED_FILE =
  "You haven't uploaded any files yet! Upload a PPT file to start.";

// Sanitization window
export const SLIDE_VIEWS = {
  IDENTIFIED: "Identified",
  ALL: "All"
};

export const SANITIZED_ENTITY = "Sanitized";

// Preview Document
// Slide Direction
export const LEFT_DIRECTION = "left";
export const RIGHT_DIRECTION = "right";

// Slide Label
export const ORIGINAL_SLIDE = "Original Slide";
export const SANITIZED_SLIDE = "Sanitized Slide";

// Download modal
export const DOCUMENT_SUMMARY = "Document Summary";
export const TOTAL_SLIDES = "Total Slides";
export const SANITIZED_SLIDES = "Sanitized Slides";
export const PENDING_SLIDES = "Pending Slides";
export const DELETE_NOTE =
  "Document will be deleted after 21 days of download date.";
export const HYPERLINK_NOTE =
  "All notes, comments & hyperlinks will be removed in the sanitized version available for download.";

// Image preview
export const PLEASE_SELECT_IMAGE_TO_PREVIEW_MESSAGE =
  "Please select an image to see the preview";

// Graph preview
export const PREVIEW_SANITIZED_GRAPHS = "Preview Sanitized Graphs";
export const SELECT_GRAPH_TO_PREVIEW =
  "Please select an graph image to see the preview";

// Table preview
export const PREVIEW_SANITIZED_TABLE = "Preview Sanitized Tables";
export const PLEASE_SELECT_TABLE_TO_PREVIEW =
  "Please select an table to see the preview";

export const TEXT_DELETED = "Text deleted";

// Image Manual Editor
export const IMAGE_MANUAL_EDITOR = {
  IMAGE_EDITOR_HEADER: "Image Editor",

  PIXELATE: "pixelate",
  BLUR: "Blur",
  FILTER: "filter",
  TRANSPARENT: "transparent",
  GRAYSCALE:"Grayscale",

  // Main Menu
  SELECT: "select",
  TEXT: "text",
  STAMP: "stamp",
  FUZZIFY: "fuzzify",

  SHAPE: "shape",
  ICON: "icon",

  // Select Sub Menu
  SELECT_AREA: "Select area",

  // Stamp Sub Menu
  LOGO: "Logo",
  PORTRAIT: "Portrait",
  GENERIC: "Generic",

  STOCK: "Stock",

  // Text Sub Menu
  DRAW_SHAPE: "Draw Shape",
  ADD_TEXT: "Add text",

  // Typography
  BOLD: "bold",
  ITALIC: "italic",
  UNDERLINE: "underline",

  FONT_FAMILY: "Henderson BCG Sans",

  // Shapes
  RECTANGLE: "rect",
  CIRCLE: "circle",

  TYPE_HERE: "Type here..."
};
export const DOCUMENT_NOT_FOUND = "Document not found";

// Tooltip messages
export const TOOLTIP_MESSAGE_MANUAL_EDIT = (type = "text") =>
  `Manually edited ${type.toLowerCase()} will be used for training AI model and future suggestions`;
export const TOOLTIP_MESSAGE_MANUAL_EDIT_TABLE = `Click to manually edit cell level text and apply smart suggestion from AI engine`;
export const TOOPTIP_FILTER = "Filter system identified options";
export const SAVE_HOME = "Save and Return to Home Page";

export const TABLE_ME = {
  GO_BACK: "Go to Table list",
  PREV_COL: "Go to Previous column",
  NEXT_COL: "Go to Next column"
};
