import {
  put,
  takeEvery,
  call,
  select,
  takeLatest,
  delay,
  all,
  takeLeading
} from "redux-saga/effects";
import { chunk, isEmpty, last, map, random, reverse } from "lodash";
import moment from "moment";
import { ROUTE_CHANGE } from "redux/withRouteChange";
import * as at from "../types";
import CONFIG from "configs/config";
import { fetchStatusByPptId } from "api/home";
import {
  rsDeleteFile,
  rsGetFiles,
  rsGetPrompt,
  rsUploadDocument,
  rsAutoSanitizeDocument,
  rsRetryFailedDocument
} from "./resourceSagas";
import ToastService from "utils/toast";
import {
  getFilesToRetry,
  getFilesWithOpenStatusForPolling
} from "../selectors";
import {
  getFilesAction,
  checkForRetryFailedDocument,
  updateFileStatusByIdAction
} from "../actions";
import { togglePreviewDocAction } from "containers/Preview/actions";
import { onAAClickEvents } from "analytics/AnalyticsCapture";

function* retryDocs(failedDocs) {
  const chunks = reverse(chunk(failedDocs, CONFIG.CONSTANTS.RETRY_BATCH_SIZE));
  while (chunks.length) {
    try {
      const callstack = map(last(chunks), (file) =>
        call(rsRetryFailedDocument, file.pptId)
      );
      yield delay(random(0, 10000)); // apply random delay to trigger retry so that when opening at the same time in two browser will not trigger same time
      yield all(callstack);
      chunks.pop();
      yield delay(CONFIG.CONSTANTS.RETRY_BATCH_INTERVAL); // help server to process only 3 (batch size) at a time, after (3 mins) batch interval
    } catch (e) {
      continue;
    }
  }
}

export default function* watchersSaga() {
  /**
   * Listener saga for Upload ppt action, and trigger upload api
   * Toast service is used to show error message
   */
  yield takeEvery(
    at.UPLOAD_POWER_POINT_FILES,
    function* uploadPowerPointFilesSaga(data) {
      try {
        let toastMessage = "";
        if (data.payload?.pptId) {
          onAAClickEvents(data.payload.pptTitle, "Doc_upload_start");
          yield call(rsUploadDocument, data.payload);
        } else {
          toastMessage = CONFIG.ERROR.API.UPLOAD_DOCUMENT.GENERAL;
        }

        if (toastMessage) {
          ToastService.error(toastMessage);
        }
      } catch (e) {
        yield put(getFilesAction());
      }
    }
  );

  /**
   * Pooling of file state if any one of the file inside the list is processing
   * Listener saga, triggered when upload document api is succeded, and get files succeded (when refreshing files list)
   * Also, listen route change action to destroy previous execution of pooling (To stop pooling when moving away from home page)
   */
  yield takeLatest(
    [
      at.UPLOAD_POWER_POINT_FILES_SUCCEEDED,
      at.GET_FILES_SUCCEEDED,
      at.RETRY_FAILED_DOCUMENT_SUCCEEDED,
      ROUTE_CHANGE
    ],
    function* onUploadFileSuccess({ type }) {
      if (type !== ROUTE_CHANGE) {
        // When file is uploaded successfully, we get ppt response with uploaded status, use that as first response, and trigger polling
        if (type === at.UPLOAD_POWER_POINT_FILES_SUCCEEDED) {
          yield delay(CONFIG.CONSTANTS.POLL_INTERVAL);
        }
        let filesList = yield select(getFilesWithOpenStatusForPolling);
        while (filesList.length) {
          try {
            filesList.map(({ pptTitle, pptStatus }) =>
              pptStatus === CONFIG.CONSTANTS.UPLOAD_FILE_STATUSES.UPLOADED
                ? onAAClickEvents(pptTitle, "Doc_upload_end")
                : ""
            );
            const response = yield call(
              fetchStatusByPptId,
              filesList.map((f) => f.pptId)
            );
            if (!isEmpty(response)) {
              yield put(updateFileStatusByIdAction(response));
              yield delay(CONFIG.CONSTANTS.POLL_INTERVAL);
              filesList = yield select(getFilesWithOpenStatusForPolling);
              yield put(checkForRetryFailedDocument());
            } else {
              filesList = [];
            }
          } catch (e) {
            const { status } = e?.response || {};
            // consider it as api failure and restart the polling
            if (status) {
              yield delay(CONFIG.CONSTANTS.POLL_INTERVAL);
            } else {
              throw e;
            }
          }
        }
      }
    }
  );
  /**
   * Delete file listener saga, and call respective api
   */
  yield takeLatest(at.DELETE_FILE, function* deleteFileSaga(data) {
    yield call(rsDeleteFile, data.payload?.pptId);
  });

  /**
   * Get files listener saga, and call respective api, refresh file list when delete action is failed
   */
  yield takeLatest(
    [at.GET_FILES, at.DELETE_FILE_FAILED],
    function* getFilesSaga() {
      yield call(rsGetFiles);
    }
  );

  /**
   * Get Prompt listener saga, and call respective api
   */
  yield takeLatest([at.GET_PROMPT], function* getPromptSaga(data) {
    yield call(rsGetPrompt, data?.payload);
  });

  /**
   * Auto sanitize listener saga, and call respective api,
   * When succeded, open preview document
   */
  yield takeEvery(at.AUTO_SANITIZE, function* autoSanitizing(data) {
    try {
      if (data.payload) {
        const response = yield call(rsAutoSanitizeDocument, data.payload);
        if (response.customResponseCode === 200) {
          yield put(togglePreviewDocAction(true));
        }
      }
    } catch (e) {
      yield put({ type: at.AUTO_SANITIZE_FAILED });
    }
  });

  /**
   * Retry ppt with ExtractionFailed State
   */
  yield takeLeading(
    [at.GET_FILES_SUCCEEDED, at.CHECK_FOR_RETRY_FAILED_DOCUMENT],
    function* retryFailedFiles({ payload }) {
      let failedDocs = yield select((state) =>
        getFilesToRetry(state, payload?.data)
      );
      while (failedDocs.length) {
        const allowedFiles = failedDocs.filter(
          (file) =>
            !file.lastRetried ||
            moment().diff(moment(file.lastRetried), "mins") >
              CONFIG.CONSTANTS.FILE_RETRY_INTERVAL
        );
        if (allowedFiles.length) {
          yield* retryDocs(failedDocs);
        }
        yield delay(CONFIG.CONSTANTS.FILE_RETRY_INTERVAL);
        failedDocs = yield select(getFilesToRetry);
      }
    }
  );
}
