import { resourceSaga } from "redux/sagas/networkResource";

import * as api from "api/home";
import * as at from "../types";

export const rsGetFiles = resourceSaga(at.GET_FILES, api.getFilesApi);

export const rsDeleteFile = resourceSaga(at.DELETE_FILE, api.deleteFileApi);

export const rsGetPrompt = resourceSaga(at.GET_PROMPT, api.getPromptData);

export const rsUploadDocument = resourceSaga(
  at.UPLOAD_POWER_POINT_FILES,
  api.uploadPowerPointApi
);

export const rsAutoSanitizeDocument = resourceSaga(
  at.AUTO_SANITIZE,
  api.autoSanitizeDocument
);

export const rsRetryFailedDocument = resourceSaga(
  at.RETRY_FAILED_DOCUMENT,
  api.retryFailedDocument
);
