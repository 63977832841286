import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { noop } from "lodash";
import { useParams } from "react-router-dom";
import {
  Form,
  Icon,
  Input,
  Placeholder,
  Popup,
  Image
} from "semantic-ui-react";
import "./TextSuggestionContainer.scss";

import ResetManualChange from "components/ResetManualChange";
import {
  getSmartSuggestion,
  getSuggestionFromLS
} from "containers/SmartSuggestion/selectors";
import { getDisplayText, getSanitizedWord } from "utils/helpers";

// Images
import selectIcon from "assets/images/tick-circle-green.svg";
import gptIcon from "assets/images/gptIcon.svg";
import saveCustomTextIcon from "assets/images/tick-green-small.svg";
import editIcon from "assets/images/manual-edit.svg";
import { getUserDetails } from "containers/User/selectors";

/**
 * SANITIZE SCREEN
 * Text Suggestions
 * @returns JSX
 */
const TextSuggestionContainer = ({
  userDetails,
  activeWord,
  showLoader,
  selectedValues,
  onSetCustomText,
  onSelectSuggestion,
  onDeselectSuggestion,
  suggestionFromLS,
  currentTextId,
  isManuallyEdited,
  onResetManuallEditedText
}) => {
  let { id: pptId } = useParams();
  const [hasError, setErrorMessage] = useState(false);
  const getInitialState = () => {
    const { recommendations = [] } = activeWord;
    const selectedValue =
      getSanitizedWord(selectedValues, activeWord) ||
      suggestionFromLS[activeWord.entity];
    if (
      selectedValue &&
      !selectedValue.isFromSuggestion &&
      !selectedValue.hasReset &&
      recommendations.filter((value) => value === selectedValue.updatedText)
        .length === 0
    ) {
      return getDisplayText(selectedValue.updatedText);
    } else {
      return "";
    }
  };
  const customInputRef = useRef(null);
  const [customInputText, setCustomInputText] = useState(getInitialState());

  useEffect(() => {
    const newStateValue = getInitialState();
    setCustomInputText(newStateValue);
  }, [selectedValues, activeWord]);

  const [isEditing, setIsEditing] = useState(false);

  const onSuggestionChange = (suggestion, isSelected) => {
    const basic = {
      ...activeWord,
      pptId,
      textId: currentTextId,
      updatedText: isSelected ? suggestion : null
    };
    isSelected
      ? onDeselectSuggestion({
          ...basic,
          updatedText: suggestion,
          isUpdated: false,
          isReset: false
        })
      : onSelectSuggestion({
          ...basic,
          updatedText: suggestion,
          isUpdated: true,
          isReset: false
        });
  };

  const onCustomInputChange = (e) => {
    const inputValue = e.target.value;
    setCustomInputText(inputValue);
  };

  const onCustomTextSubmit = () => {
    const errorMessage = "";
    if (errorMessage) {
      setErrorMessage(errorMessage);
    } else if (customInputText) {
      const payload = {
        ...activeWord,
        updatedText: customInputText,
        isUpdated: true,
        isReset: false,
        pptId,
        textId: currentTextId
      };
      onSetCustomText(payload);
    }
  };

  const hasSelected = (suggestion) => {
    const value = getSanitizedWord(selectedValues, activeWord);
    if (value) {
      return getDisplayText(value.updatedText) === suggestion;
    } else if (suggestionFromLS[activeWord.entity]) {
      return suggestionFromLS[activeWord.entity].updatedText === suggestion;
    }
  };

  const hasCustomTextSaved =
    customInputText && hasSelected(customInputText) && !isEditing;

  const selectedIcon = <img className="select-icon" src={selectIcon} alt="" />;

  if (showLoader || !userDetails.hrId) {
    return (
      <div className="suggestion-container">
        <Placeholder />
        <Placeholder />
        <Placeholder />
      </div>
    );
  }
  return (
    <div
      className={
        "suggestion-container" + (isManuallyEdited ? "reset-message" : "")
      }
    >
      {isManuallyEdited ? (
        <ResetManualChange
          section="Text"
          onResetManualChange={() => {
            onResetManuallEditedText();
          }}
        />
      ) : (
        <>
          <ul className="suggestion-container__system-suggestions">
            {activeWord.recommendations?.map((suggestion, i) => {
              const selected = hasSelected(suggestion);
              return (
                <li
                  key={i}
                  className={selected ? "selected" : ""}
                  onClick={() => {
                    onSuggestionChange(suggestion, selected);
                  }}
                >
                  <div className="suggestion-container__system-suggestions--suggestion">
                    {i === 0 && (
                      <Image
                        className="mr-2"
                        src={gptIcon}
                        alt="Smart Suggestions"
                        verticalAlign="top"
                        width="12px"
                        height="20px"
                      />
                    )}
                    <span>{suggestion}</span>
                  </div>
                  {selectedIcon}
                </li>
              );
            })}
          </ul>

          <div
            className={`suggestion-container__custom-input ${
              hasCustomTextSaved
                ? "has-custom-text"
                : hasError
                ? "has-error"
                : ""
            }`}
          >
            <div className="suggestion-container__custom-input-header">
              <label className="pb-2" htmlFor="customText">
                Type Custom
              </label>
              {hasError && (
                <Popup
                  className="warning"
                  position="top right"
                  content={hasError}
                  trigger={<Icon name="warning sign" color="yellow" />}
                />
              )}
            </div>

            <Form
              onSubmit={() => {
                onCustomTextSubmit();
                setIsEditing(false);
              }}
            >
              <Input
                onFocus={() => {
                  setIsEditing(true);
                }}
                onBlur={() => {
                  setIsEditing(false);
                }}
                ref={customInputRef}
                autoComplete="off"
                name="customText"
                className="w-100"
                value={customInputText}
                placeholder={!customInputText ? "Type text here" : ""}
                onChange={(e) => {
                  onCustomInputChange(e);
                }}
              />
              {!isEditing && (
                <img
                  className={`custom-edit-icon`}
                  src={editIcon}
                  alt="Edit"
                  onClick={() => {
                    customInputRef.current.focus();
                    setIsEditing(true);
                  }}
                />
              )}
              <img
                className={`custom-text-icon ${
                  isEditing && "custom-text-icon--editing"
                } ${
                  (!customInputText || hasError) &&
                  "custom-text-icon--not-valid"
                }`}
                src={saveCustomTextIcon}
                alt="Save"
                onClick={onCustomTextSubmit}
              />
              {selectedIcon}
            </Form>
          </div>
        </>
      )}
    </div>
  );
};

TextSuggestionContainer.propTypes = {
  userDetails: PropTypes.object,
  activeWord: PropTypes.object,
  showLoader: PropTypes.bool,
  selectedValues: PropTypes.array,
  onSetCustomText: PropTypes.func,
  onSelectSuggestion: PropTypes.func,
  onDeselectSuggestion: PropTypes.func,
  suggestionFromLS: PropTypes.object,
  currentTextId: PropTypes.string,
  isManuallyEdited: PropTypes.bool,
  onResetManuallEditedText: PropTypes.func
};

TextSuggestionContainer.defaultProps = {
  userDetails: {},
  activeWord: {},
  selectedValues: [],
  onSetCustomText: noop,
  onSelectSuggestion: noop,
  onDeselectSuggestion: noop,
  suggestionFromLS: {},
  currentTextId: "",
  isManuallyEdited: false,
  onResetManuallEditedText: noop
};

const mapStateToProps = (state) => ({
  selectedValues: getSmartSuggestion(state),
  suggestionFromLS: getSuggestionFromLS(state),
  userDetails: getUserDetails(state)
});

export default connect(mapStateToProps)(TextSuggestionContainer);
