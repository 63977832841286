import React from "react";
import "./Prompts.scss";
import CONFIG from "configs/config";
import { Header } from "semantic-ui-react";
import SimpleBar from "simplebar-react";
import CustomActionButton from "components/CustomActionButton/CustomActionButton";
import PropTypes from "prop-types";

const Prompts = ({ onTextChange, text, getPromptAction }) => {
  return (
    <>
      <div className="prompts">
        <Header as="h4">{CONFIG.LABELS.PROMPT}</Header>
        <SimpleBar
          className="prompt__preview"
          scrollbarMaxSize={50}
          timeout={1500}
        >
          <textarea
            spellCheck
            className="prompt-manual-editor__editor--textarea"
            value={text}
            onChange={(event) => onTextChange(event)}
          />
        </SimpleBar>
        <div className="prompt-manual-editor__footer--actions">
          <CustomActionButton
            label={CONFIG.LABELS.APPLY}
            className="ml-2"
            size="mini"
            onClickfn={() => {
              getPromptAction({
                gptPrompt: text
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

Prompts.propTypes = {
  onTextChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  getPromptAction: PropTypes.func.isRequired
};

export default Prompts;
