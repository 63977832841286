import axiosInstance from "configs/axios";
import CONFIG from "configs/config";
import { compact, get, map } from "lodash";
import { extractApiResponse } from "utils/helpers";

// URIs
const URI_TEXT_CONTENTS = "/Text/getbyslideid/";
const URI_TEXT_CONTENTS_PARA = "/Text/getbytextid/";
const URI_TEXT_SAVE_PARA = "/Text/updatebytextid";
const URI_TEXT_RECOMMEDATION = "/Text/recommendation";

export function Portion(result, addSpaceSeparator) {
  this.id = result.id;
  this.groupId = result.groupId;
  this.parentId = result.parentId;
  this.separator = addSpaceSeparator ? " " : "";
  this.isGroupManuallyEdited = result.isGroupManuallyEdited;
  this.text = result.text;
  this.isUpdated = result.isUpdated;
  this.isReset = result.isReset;
  this.updatedText = result.updatedText;
  this.isIdentified = result.isIdentified;
  this.isFromSuggestion = result.isFromSuggestion;
  this.identifiedEntityGroup = result.identifiedPortionGroup;
}

export function TextList(result, index, pptId) {
  if (index !== null) {
    this.idx = index + 1;
  }
  this.id = result.textId;
  this.slideId = result.slideId;
  this.pptId = result.presentationId || pptId;
  this.text = result.text;
  this.isIdentified = result.isIdentified;
  this.portionIds = result.portionIds;
  this.portions = map(
    result.portions,
    (portion, i) =>
      new Portion(
        portion,
        portion.parentId !== (result.portions[i + 1] || {}).parentId
      )
  );
}

export function getTextListByPptId(pptId, slideId) {
  return axiosInstance
    .get(`${URI_TEXT_CONTENTS}${slideId}`)
    .then((response) =>
      map(
        extractApiResponse(response),
        (result, idx) => new TextList(result, idx, pptId)
      )
    );
}

export function SensitiveEntities(entry) {
  this.entity = entry.entity;
  this.startPos = entry.startPosition;
  this.endPos = entry.endPosition;
  this.entityIds = entry.identitifiedPortions.map((id) => id.portionId);
  this.recommendations = (entry.recommendations || []).slice(0, 2);
}

export function Suggestions(data) {
  this.pptId = data.pptId;
  this.id = data.textId;
  this.entities = compact(
    map(get(data, "identifiedEntities", []), (entry) =>
      entry.identitifiedPortions.length ? new SensitiveEntities(entry) : false
    )
  ); // Remove entities if there is no portionIds
}

// From text recommendation service
export function getSuggestionsByParaId(slideId, textId, text) {
  return axiosInstance
    .post(`${CONFIG.SERVER.BASE_RECOMMENDATION_URL}${URI_TEXT_RECOMMEDATION}`, {
      SlideId: slideId,
      TextId: textId,
      gptPrompt: text
    })
    .then((response) => new Suggestions(extractApiResponse(response)));
}

export function getSanitizedPara(slideId, textId, pptId) {
  return axiosInstance
    .get(`${URI_TEXT_CONTENTS_PARA}${slideId}/${textId}`)
    .then(
      (response) => new TextList(extractApiResponse(response), null, pptId)
    );
}

function SanitizedPortion(portion) {
  this.id = portion.id;
  this.parentId = portion.parent;
  this.text = portion.text;
  this.groupId = portion.originalGroupId || portion.groupId;
  this.isGroupManuallyEdited = portion.isGroupManuallyEdited;
  this.isUpdated = portion.isUpdated;
  this.isReset = portion.isReset;
  this.updatedText = portion.updatedText;
  this.isIdentified = portion.isIdentified;
  this.isFromSuggestion = portion.isFromSuggestion;
  this.identifiedPortionGroup = portion.identifiedEntityGroup;
}

function SanitizedPara(input) {
  this.slideId = input.slideId;
  this.textId = input.id;
  this.portions = map(
    input.portions,
    (portion) => new SanitizedPortion(portion)
  );
}

export function saveSanitizedPara(payload) {
  if (payload.pptId) {
    return axiosInstance
      .put(`${URI_TEXT_SAVE_PARA}/${payload.pptId}`, new SanitizedPara(payload))
      .then(() => payload);
  }
  return Promise.reject("No pptId found");
}
