import { requestedString, succeededString, failedString } from "utils/common";

export const SET_DICLAIMER_STATUS = "SET_DICLAIMER_STATUS";

export const UPLOAD_POWER_POINT_FILES = "UPLOAD_POWER_POINT_FILES";
export const UPLOAD_POWER_POINT_FILES_REQUESTED = requestedString(
  UPLOAD_POWER_POINT_FILES
);
export const UPLOAD_POWER_POINT_FILES_SUCCEEDED = succeededString(
  UPLOAD_POWER_POINT_FILES
);
export const UPLOAD_POWER_POINT_FILES_FAILED = failedString(
  UPLOAD_POWER_POINT_FILES
);

export const UPDATE_FILE_STATUS_BY_ID = "UPDATE_FILE_STATUS_BY_ID";

export const GET_FILES = "GET_FILES";
export const GET_FILES_REQUESTED = requestedString(GET_FILES);
export const GET_FILES_SUCCEEDED = succeededString(GET_FILES);

export const DELETE_FILE = "DELETE_FILE";
export const DELETE_FILE_SUCCEEDED = succeededString(DELETE_FILE);
export const DELETE_FILE_FAILED = failedString(DELETE_FILE);

export const GET_PROMPT = "GET_PROMPT";
export const GET_PROMPT_REQUESTED = requestedString(GET_PROMPT);
export const GET_PROMPT_SUCCEEDED = succeededString(GET_PROMPT);

export const RESET_HOME_STORE = "RESET_HOME_STORE";

export const ON_HOME_PAGE_REDIRECT = "ON_HOME_PAGE_REDIRECT";

export const AUTO_SANITIZE = "AUTO_SANITIZE";
export const AUTO_SANITIZE_FAILED = failedString(AUTO_SANITIZE);

export const SET_SELECTED_PPT_ID = "SET_SELECTED_PPT_ID";

export const CHECK_FOR_RETRY_FAILED_DOCUMENT =
  "CHECK_FOR_RETRY_FAILED_DOCUMENT";
export const RETRY_FAILED_DOCUMENT = "RETRY_FAILED_DOCUMENT";
export const RETRY_FAILED_DOCUMENT_SUCCEEDED = succeededString(
  RETRY_FAILED_DOCUMENT
);
